.grid {
    display: grid;
    @media (max-width: 719px) {
        grid-template-columns:  1fr 1fr [m] 1fr 1fr;
        padding: 1rem;
        column-gap: 1rem;
    }

    @media (min-width: 720px) and (max-width: 1024px) {
        grid-template-columns: 1fr repeat(2, 1fr) 1fr [m] 1fr repeat(2, 1fr) 1fr;
        padding: 1.5rem;
        column-gap: 2rem;
    }

    @media (min-width: 1025px) and (max-width: 1366px) {
        grid-template-columns: 1fr repeat(4, 1fr) 1fr [m] 1fr repeat(4, 1fr) 1fr;
        padding: 2.5rem;
        column-gap: 1rem;
    }

    @media (min-width: 1367px) {
        grid-template-columns: minmax(24px, auto) minmax(auto, 88px) repeat(4, minmax(auto, 88px)) minmax(auto, 88px) [m] minmax(auto, 88px) repeat(4, minmax(auto, 88px)) minmax(auto, 88px) minmax(24px, auto);
        padding: 2.5rem 0;
        column-gap: 1rem;
        & > div {
            width: 100%;
            grid-column: 2 / -2 !important;
        }
    }

    & > div {
        grid-column: 1 / -1;
    }
}
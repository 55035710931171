@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
/* ESCOLHA O TAMANHO DA FONTE AQUI */
$font-size: 1rem;
/* ESCOLHA A FONTE AQUI */
$font-family: 'Open Sans', sans-serif;




/* MEDIA QUERYS NO $font-size PARA ALTERAR RESPONSIVIDADE */

